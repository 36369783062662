import React, { useState, useRef } from "react";
import axios from "axios";
import SweetAlert from 'sweetalert2-react';
import InputAdornment from '@material-ui/core/InputAdornment';
import Badge from '@material-ui/core/Badge';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import {
  Container,
  Grid,
  Button,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
  FormControl,
  Input,
  InputLabel,
  FormHelperText,
  Select,
  MenuItem,
  Snackbar
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import piknikLogo from "./assets/img/piknik-logo.png";
import "./App.css";

let apiUrl = process.env.REACT_APP_API;

const centeredStyleObj = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center"
};
const useStyles = makeStyles({
  container: {
    minHeight: "100vh",
    flexDirection: "column",
    ...centeredStyleObj
  },
  tableCardcontainer: {
    width: "100%",
    flexDirection: "column",
    ...centeredStyleObj
  },
  cardContainer: {
    flexDirection: "column",
    width: 400,
    height: 300,
    padding: "2rem",
    ...centeredStyleObj
  },

  cardContainerLg: {
    flexDirection: "column",
    width: '80%',
    padding: "2rem",
    ...centeredStyleObj,
    paddingBottom: '10px'
  },

  cardContainerInvoiceItems: {
    flexDirection: "column",
    width: "100%",
    padding: "1rem",
    ...centeredStyleObj
  },
  invoiceFormContainer: {
    flexDirection: "column",
    width: "100%",
    padding: "1rem",
    ...centeredStyleObj
  },
  tittleGridContainer: {
    ...centeredStyleObj
  },
  businessContainer: {
    width: "100%",
    margin: ".5rem",
    padding: "1rem"
  },
  tittle: {
    fontSize: "4rem"
  },
  textFieldSearch: {
    width: "90%"
  },
  searchButton: {
    marginLeft: ".5rem"
  },
  buttonsContainer: {
    marginTop: "1.5rem"
  },
  movieIcon: {
    fontSize: "3rem"
  },
  table: {
    width: '90%'
  }
});

// const scrollToRef = ref => window.scrollTo(0, ref.current.offsetTop);

const Logo = () => {
  return(
    <Grid>
      <img src={piknikLogo} alt="" height="150px" />
    </Grid>
  );
}

function App() {


  const states = [{"id":1,"name":"Aguascalientes"},{"id":2,"name":"Baja California"},{"id":3,"name":"Baja California Sur"},{"id":4,"name":"Campeche"},{"id":5,"name":"Coahuila de Zaragoza"},{"id":6,"name":"Colima"},{"id":7,"name":"Chiapas"},{"id":8,"name":"Chihuahua"},{"id":9,"name":"Distrito Federal"},{"id":10,"name":"Durango"},{"id":11,"name":"Guanajuato"},{"id":12,"name":"Guerrero"},{"id":13,"name":"Hidalgo"},{"id":14,"name":"Jalisco"},{"id":15,"name":"México"},{"id":16,"name":"Michoacán de Ocampo"},{"id":17,"name":"Morelos"},{"id":18,"name":"Nayarit"},{"id":19,"name":"Nuevo Leó"},{"id":20,"name":"Oaxaca"},{"id":21,"name":"Puebla"},{"id":22,"name":"Querétaro"},{"id":23,"name":"Quintana Roo"},{"id":24,"name":"San Luis Potosí"},{"id":25,"name":"Sinaloa"},{"id":26,"name":"Sonora"},{"id":27,"name":"Tabasco"},{"id":28,"name":"Tamaulipas"},{"id":29,"name":"Tlaxcala"},{"id":30,"name":"Veracruz de Ignacio de la Llave"},{"id":31,"name":"Yucatán"},{"id":32,"name":"Zacatecas"}]

  const myRef = useRef(null);
  const invoiceUse = [
    { title: "ADQUISICION DE MERCANCIAS", code: "G01" },
    { title: "DEVOLUCIONES, DESCUENTOS, BONIFICACIONES", code: "G02" },
    { title: "GASTOS EN GENERAL", code: "G03" },
    { title: "CONSTRUCCIONES", code: "I01" },
    { title: "MOBILIARIO Y EQUIPO DE OFICINA", code: "I02" },
    { title: "EQUIPO DE TRANSPORTE", code: "I03" },
    { title: "EQUIPO DE COMPUTO", code: "I04" },
    { title: "DADOS TROQUELES HERRAMENTAL", code: "I05" },
    { title: "COMUNICACIONES TELEFONICAS", code: "I06" },
    { title: "COMUNICACIONES SATELITALES", code: "I07" },
    { title: "OTRA MAQUINARIA", code: "I08" },
    { title: "HONORARIOS MEDICOS", code: "D01" },
    { title: "GASTOS MEDICOS POR INCAPACIDAD", code: "D02" },
    { title: "GASTOS FUNERALES", code: "D03" },
    { title: "DONATIVOS", code: "D04" },
    { title: "INTERESES POR CREDITOS HIPOTECARIOS", code: "D05" },
    { title: "APORTACIONES VOLUNTARIAS SAR", code: "D06" },
    { title: "PRIMA SEGUROS GASTOS MEDICOS", code: "D07" },
    { title: "GASTOS TRANSPORTACION ESCOLAR", code: "D08" },
    { title: "CUENTAS AHORRO PENSIONES", code: "D09" },
    { title: "SERVICIOS EDUCATIVOS", code: "D10" },
    { title: "POR DEFINIR", code: "P01" }
  ];
  const invoiceFormRef = useRef(null);
  // const executeScroll = () => scrollToRef(myRef);
  // const executeScrollToInvoiceForm = () => scrollToRef(invoiceFormRef);
  const [searchText, setSearchText] = useState("");
  const [customerName, setCustomerName] = useState("");
  // const [customerLastName, setCustomerLastName] = useState("");
  const [customerLegalName, setCustomerLegalName] = useState("");
  const [resultRequest, setResultRequest] = useState(null);
  const [textResultRequest, setTextResultRequest] = useState(null);
  const [displayOne, setDisplayOne] = useState('flex');
  const [displayTwo, setDisplayTwo] = useState('none');
  const [displayThree, setDisplayThree] = useState('none');
  const [taxId, setTaxId] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [street, setStreet] = useState("");
  const [distric, setDistrict] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [interiorNumber, setInteriorNumber] = useState("");
  const [streetNumber, setStreetNumber] = useState("");
  const [city, setCity] = useState("");
  const [customerType, setCustomerType] = useState("");
  const [cfdiType, setCfdiType] = useState("");
  const [stateEntity, setStateEntity] = useState("");
  const [township, setTownship] = useState("");
  const [statesData, setStatesData] = useState(states);
  const [towshipsData, setTownshipsData] = useState("");
  const [companiesData, setCompaniesData] = useState("");
  const [open, setOpen] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [messageAlert, setMessageAlert] = useState('');
  const [titleAlert, setTitleAlert] = useState('');
  const [tableSession, setTableSession] = useState(null);
  const [isLoadingGenerateInvoice, setIsLoadingGenereateInvoice] = useState(false);
  const handleClose = () => {
    if(resultRequest == 'success') {
      setSearchText('');
      setCompaniesData(null);
      setResultRequest(null);
      setCustomerLegalName(null);
      setCustomerName(null);
      setCfdiType(null);
      setCity(null);
      setPostalCode(null);
      setInteriorNumber(null);
      setStreet(null);
      setDistrict(null);
      setEmail(null);
      setPhone(null);
      setTaxId(null);
      setDisplayTwo('none')
      setDisplayThree('none')
      setDisplayOne('flex')
    }
    setOpen(false);
  };
  const onClickSearchAccount = async event => {
    console.log(event);
    console.log(searchText);

    if(!searchText) {
      setTitleAlert('Dato invalido');
      setMessageAlert('Ingrese un número de cuenta para facturar')
      setShowAlert(true)
      return;
    }

    const response = await performAccountRequest(searchText);
    let { previewItems, tableSession } = response.data.data
    if(previewItems.length) {
      setCompaniesData(previewItems);
      setTableSession(tableSession)
      // const states = await getStates();
      // console.log("states", JSON.stringify(states.data.data))
      // setStatesData(states.data.data);
      setDisplayOne('none')
      setDisplayTwo('flex')
    } else {
      setMessageAlert('El número de cuenta no ha sido encontrado')
      setShowAlert(true)
    }
    // console.log("companiesData", companiesData);
    // console.log("states", statesData);
  };
  const onClickCreateInvoice = async event => {
    setDisplayTwo('none');
    setDisplayThree('flex');
  };
  const handleChangeCustomerType = async event => {
    console.log(event);
    setCustomerType(event.target.value);
  };
  const handleChangeCfdiType = async event => {
    console.log(event);
    setCfdiType(event.target.value);
  };
  const handleChangeState = async event => {
    console.log(event);
    setStateEntity(event.target.value);
    console.log(stateEntity);
    const townships = await getTownships(event.target.value);
    setTownshipsData(townships.data.data);
    console.log(towshipsData);
  };
  const handleChangeTownship = async event => {
    console.log(event);
    setTownship(event.target.value);
  };
  const showInfo = async(title, text) => {
    setTitleAlert(title);
    setMessageAlert(text)
    setShowAlert(true)
  }
  // const onSubmitInvoiceForm = async event => {
  //   const response = await performAccountRequest(searchText);
  //   setCompaniesData(response.data.data);
  //   console.log("companiesData", companiesData);
  // };
  const handleAccountValueTextField = event => {
    setSearchText(event.target.value);
  };

  const performAccountRequest = accountId => {
    return axios
      .get(`${apiUrl}/invoices/${searchText}`)
      .then(response => {
        return response;
      })
      .catch(err => {
        console.log(err);
      });
  };
  const createInvoice = () => {
    setIsLoadingGenereateInvoice(true);
    axios
      .post(`${apiUrl}/invoices`, {
        tableSessionId: searchText,
        customer: {
          name: customerName,
          // lastName: customerLastName,
          legalName: customerLegalName,
          taxId: taxId,
          email: email,
          regime: customerType,
          phone: phone,
          address: {
            street: street,
            streetNumber: streetNumber,
            district: distric,
            postalCode: postalCode,
            interiorNumber: interiorNumber,
            city: city,
            townshipId: township
          }
        }
      })
      .then(response => {
        setResultRequest('success')
        setTextResultRequest('La factura ha sido generada exitosamente y ha sido enviada a su correo')
        setOpen(true);
        setIsLoadingGenereateInvoice(false);
        console.log(response);
      })
      .catch(error => {
        error = error.response.data;
        setResultRequest('error')
        console.log("error", JSON.stringify(error))
        if(error.error.errors) {
          setTextResultRequest(`${error.error.errors[0]}`)
        } else {
          setTextResultRequest(`Ha ocurrido un error al generar la factura.`)
        }
        setOpen(true);
        setIsLoadingGenereateInvoice(false);
        console.error(error);
      });
  };
  const getStates = () => {
    return axios
      .get(`${apiUrl}/invoices/states`)
      .then(response => {
        return response;
      })
      .catch(err => {
        console.log(err);
      });
  };
  const getTownships = stateId => {
    return axios
      .get(`${apiUrl}/invoices/townships/${stateId}`)
      .then(response => {
        return response;
      })
      .catch(err => {
        console.log(err);
      });
  };
  const classes = useStyles();

  const bigImageStyles = {
    backgroundImage: "url(./Imagen15.png)",
    width: '100%'
  }



  return (
    <div style={bigImageStyles}>
     <div>
      <Container className={classes.container} style={{display: displayOne}}>
        <Card className={classes.cardContainer}>
          <Grid container className={classes.tittleGridContainer}>
            <Logo/>
          </Grid>
          <Input
            id="searchAccountInput"
            className={classes.textFieldSearch}
            value={searchText}
            placeholder="Ingrese su número de cuenta..."
            onChange={handleAccountValueTextField}
            endAdornment={
              <InputAdornment position="end"
                onClick={
                  () => showInfo(
                  'Información',
                  'Puedes encontrar el número de cuenta en tu Ticket de consumo. El número de cuenta se encuentra en la parte superior del Ticket.'
                  )
                }
              >
                <HelpOutlineIcon />
              </InputAdornment>
            }
          />
          <Grid className={classes.buttonsContainer}>
            <Button
              variant="contained"
              className={classes.searchButton}
              color="primary"
              size="large"
              style={
                {
                background: "#000"
                }
              }
              onClick={onClickSearchAccount}
            >
              Buscar
            </Button>
          </Grid>
        </Card>
      </Container>

      <SweetAlert
        show={showAlert}
        title={titleAlert}
        text={messageAlert}
        onConfirm={() => setShowAlert(false)}
      />
      </div>

      <Container className={classes.container} ref={myRef} style={{display: displayTwo}}>
      <Card className={classes.cardContainerLg}>
        <Grid className={classes.cardContainerInvoiceItems}>
            <Logo/>
            <Typography variant="h3">Facturas Disponibles</Typography>
            {companiesData ? (
              companiesData.map(row => (
                <Box className={classes.businessContainer} key={row.company.rfc}>
                  <Grid container className={classes.tableCardcontainer}>
                    <Grid item className={classes.businessContainer}>
                      <TableContainer component={Paper}>
                        <Table
                          className={classes.table}
                          aria-label="simple table"
                        >
                          <TableHead>
                            <TableRow>
                              <TableCell align="right">Código</TableCell>
                              <TableCell>Empresa</TableCell>
                              <TableCell>Platillo</TableCell>
                              <TableCell align="right" width="20%">Cantidad</TableCell>
                              <TableCell align="right" width="20%">Precio</TableCell>
                              <TableCell align="right" width="20%">Estatus</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {row.company.items ? (
                              row.company.items.map(invoiceItem => (
                                <TableRow key={invoiceItem.product.product_key}>
                                  <TableCell align="right" width="20%">
                                    {invoiceItem.product.product_key}
                                  </TableCell>
                                  <TableCell align="left" width="20%">
                                    {row.company.businessName}
                                  </TableCell>
                                  <TableCell component="th" scope="row">
                                    {invoiceItem.product.description}
                                  </TableCell>
                                  <TableCell align="right">
                                    {invoiceItem.quantity}
                                  </TableCell>
                                  <TableCell align="right">
                                    {`$ ${invoiceItem.product.price}`}
                                  </TableCell>
                                  <TableCell align="right">
                                    { row.company.hasKey
                                    ?
                                      (
                                        invoiceItem.alreadyBilled ?
                                        <label style={{color:'green'}}>
                                        Facturado
                                      </label>
                                      :
                                      <label style={{color:'blue'}}>
                                      Por Facturar
                                      </label>
                                      )
                                    :
                                    <label
                                      style={{color:'red'}}
                                      onClick={() => showInfo('Información', `No puedes facturar este producto en este portal web, comunicate con la empresa ${row.company.name} al correo ${row.company.email}`) }
                                    >
                                        Inhabilitado
                                    </label>
                                     }
                                  </TableCell>
                                </TableRow>
                              ))
                            ) : (
                              <Typography>
                                Ingresa el número de orden para visualizar los
                                elementos
                              </Typography>
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                  </Grid>
                </Box>
              ))
            ) : (
              <Typography variant="h5">
                Ingresa el número de orden para visualizar los elementos
              </Typography>
            )}
            {companiesData ? (
              <Grid className={classes.buttonsContainer}>
                <Button
                  style={{ marginRight: '30px' }}
                  onClick={handleClose}
                >
                  Cancelar
                </Button>
                {
                  tableSession && tableSession.alreadyBilled
                  ? <></>
                  :
                  <Button
                    variant="contained"
                    className={classes.searchButton}
                    color="primary"
                    size="large"
                    style={{
                      background:
                        "linear-gradient(90deg, rgba(236,185,54,1) 0%, rgba(254,95,85,1) 50%, rgba(184,12,9,1) 100%)"
                    }}
                    onClick={onClickCreateInvoice}
                  >
                    Continuar
                  </Button>
                }
              </Grid>
            ) : (
              " "
            )}
          </Grid>

      </Card>
      </Container>

        {companiesData ? (
        <Container className={classes.container} ref={invoiceFormRef} style={{display: displayThree}}>
          <Card className={classes.invoiceFormContainer}>
            <Grid container className={classes.tittleGridContainer}>
              <Grid>
                <Logo/>
                <Typography variant="h6">
                  Ingrese sus datos de facturación
                </Typography>
              </Grid>
              <Grid></Grid>
            </Grid>

            <Grid container spacing={3} justify="center">
              <Grid item md={3} >
                <FormControl>
                  <InputLabel htmlFor="my-tableSessionId">
                    Número de cuenta
                  </InputLabel>
                  <Input
                    id="tableSessionId"
                    value={searchText}
                    aria-describedby="tableSessionIdHelper"
                    disabled={true}
                  />
                  <FormHelperText id="tableSessionIdHelper">
                    Ingrese su número de ticket
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item md={3} >
                <FormControl style={{ width: '100%' }}>
                  <InputLabel id="demo-simple-select-label">
                    Tipo de persona*
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={customerType}
                    onChange={handleChangeCustomerType}
                    style={{ with: 500 }}
                  >
                    <MenuItem value={1}>Persona Física</MenuItem>
                    <MenuItem value={2}>Persona Moral</MenuItem>
                  </Select>
                  <FormHelperText id="demo-simple-select-label">
                    Seleccione el tipo de persona
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item md={3} >
                <FormControl>
                  <InputLabel htmlFor="rfc-input">RFC*</InputLabel>
                  <Input
                    id="rfc-input"
                    aria-describedby="rfc-helper-text"
                    value={taxId}
                    onChange={event => setTaxId(event.target.value)}
                  />
                  <FormHelperText id="rfc-helper-text">
                    Ingrese su RFC.
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item md={3} >
                {customerType === 2 ? (
                    <FormControl>
                      <InputLabel htmlFor="rfc-input">Razón social*</InputLabel>
                      <Input
                        id="rfc-input"
                        aria-describedby="rfc-helper-text"
                        value={customerLegalName}
                        onChange={event =>
                          setCustomerLegalName(event.target.value)
                        }
                      />
                      <FormHelperText id="rfc-helper-text">
                        Ingrese su razón social.
                      </FormHelperText>
                    </FormControl>
                ) : (
                    <FormControl>
                      <InputLabel htmlFor="rfc-input">Nombre completo*</InputLabel>
                      <Input
                        id="rfc-input"
                        aria-describedby="rfc-helper-text"
                        value={customerName}
                        onChange={event => setCustomerName(event.target.value)}
                      />
                      <FormHelperText id="rfc-helper-text">
                        Ingrese su nombre.
                      </FormHelperText>
                    </FormControl>

                )}
              </Grid>
              <Grid item md={4}>
                <FormControl style={{ width: '100%' }}>
                  <InputLabel id="cfdi-select-label">Uso de CFDI*</InputLabel>
                  <Select
                    labelId="cfdi-select-label"
                    id="cfdi-select"
                    value={cfdiType}
                    onChange={handleChangeCfdiType}
                    style={{ with: 500 }}
                  >
                    {invoiceUse.map(row => (
                      <MenuItem key={row.code} value={row.code}>
                        {row.title}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText id="cfdi-select-label">
                    Seleccione el tipo de uso de CFDI
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item md={4}>
                <FormControl style={{ width: '100%' }}>
                  <InputLabel htmlFor="email-input">
                    Correo electrónico*
                  </InputLabel>
                  <Input
                    id="email-input"
                    aria-describedby="email-helper-text"
                    type="email"
                    value={email}
                    onChange={event => setEmail(event.target.value)}
                  />
                  <FormHelperText id="email-helper-text">
                    Ingrese su correo electrónico.
                  </FormHelperText>
                </FormControl>
              </Grid>

              <Grid item md={4}>
                <FormControl style={{ width: '100%' }}>
                  <InputLabel htmlFor="phone-input">Teléfono*</InputLabel>
                  <Input
                    id="phone-input"
                    aria-describedby="phone-helper-text"
                    value={phone}
                    onChange={event => setPhone(event.target.value)}
                  />
                  <FormHelperText id="phone-helper-text">
                    Ingrese su teléfono.
                  </FormHelperText>
                </FormControl>
              </Grid>
                <Grid item>
                  <Typography variant="h6">Dirección de facturación</Typography>
                </Grid>
                <Grid item container justify="center" spacing={3}>
                  <Grid item md={3}>
                    <FormControl>
                      <InputLabel htmlFor="street-input">Calle*</InputLabel>
                      <Input
                        id="street-input"
                        aria-describedby="street-helper-text"
                        value={street}
                        onChange={event => setStreet(event.target.value)}
                      />
                      <FormHelperText id="street-helper-text">
                        Ingrese la calle.
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item md={3}>
                    <FormControl>
                      <InputLabel htmlFor="number-input">
                        Número Exterior*
                      </InputLabel>
                      <Input
                        id="number-input"
                        aria-describedby="number-helper-text"
                        value={streetNumber}
                        onChange={event => setStreetNumber(event.target.value)}
                      />
                      <FormHelperText id="number-helper-text">
                        Ingrese el número.
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item md={3}>
                    <FormControl>
                      <InputLabel htmlFor="interior-input">
                        Número Interior
                      </InputLabel>
                      <Input
                        id="interior-input"
                        aria-describedby="interior-helper-text"
                        value={interiorNumber}
                        onChange={event =>
                          setInteriorNumber(event.target.value)
                        }
                      />
                      <FormHelperText id="interior-helper-text">
                        Ingrese el número interior.
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item md={3}>
                    <FormControl>
                      <InputLabel htmlFor="distric-input">Colonia</InputLabel>
                      <Input
                        id="distric-input"
                        aria-describedby="distric-helper-text"
                        value={distric}
                        onChange={event => setDistrict(event.target.value)}
                      />
                      <FormHelperText id="distric-helper-text">
                        Ingrese la colonia.
                      </FormHelperText>
                    </FormControl>
                  </Grid>


                  <Grid item md={3}>
                    <FormControl style={{ width: '100%' }}>
                      <InputLabel id="state-select-label">Estado*</InputLabel>
                      <Select
                        labelId="state-select-label"
                        id="state-select"
                        value={stateEntity}
                        onChange={handleChangeState}
                      >
                        {statesData ? (
                          statesData.map(state => (
                            <MenuItem key={state.id} value={state.id}>
                              {state.name}
                            </MenuItem>
                          ))
                        ) : (
                          <MenuItem key={0} value={0}>
                            Seleccione
                          </MenuItem>
                        )}
                      </Select>
                      <FormHelperText id="state-select-label">
                        Seleccione el estado
                      </FormHelperText>
                    </FormControl>
                  </Grid>

                  <Grid item md={3}>
                    <FormControl style={{ width: '100%' }}>
                      <InputLabel id="township-select-label">
                        Municipio*
                      </InputLabel>
                      <Select
                        labelId="township-select-label"
                        id="township-select"
                        value={township}
                        onChange={handleChangeTownship}
                      >
                        {towshipsData ? (
                          towshipsData.map(township => (
                            <MenuItem key={township.id} value={township.id}>
                              {township.name}
                            </MenuItem>
                          ))
                        ) : (
                          <MenuItem key={0} value={0}>
                            Seleccione
                          </MenuItem>
                        )}
                      </Select>
                      <FormHelperText id="township-select-label">
                        Seleccione el municipio
                      </FormHelperText>
                    </FormControl>
                  </Grid>

                  <Grid item md={3}>
                    <FormControl>
                      <InputLabel htmlFor="zip-input">
                        Código Postal*
                      </InputLabel>
                      <Input
                        id="zip-input"
                        aria-describedby="zip-helper-text"
                        value={postalCode}
                        onChange={event => setPostalCode(event.target.value)}
                      />
                      <FormHelperText id="zip-helper-text">
                        Ingrese el código postal.
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item md={3}>
                    <FormControl>
                      <InputLabel htmlFor="city-input">Ciudad*</InputLabel>
                      <Input
                        id="city-input"
                        onChange={setCity}
                        aria-describedby="city-helper-text"
                        value={city}
                        onChange={event => setCity(event.target.value)}
                      />
                      <FormHelperText id="city-helper-text">
                        Ingrese la ciudad
                      </FormHelperText>
                    </FormControl>
                  </Grid>




                </Grid>
            </Grid>

            <Grid className={classes.buttonsContainer}>
            <Button
                  style={{ marginRight: '30px' }}
                  onClick={handleClose}
                >
                  Cancelar
                </Button>
              <Button
                variant="contained"
                className={classes.searchButton}
                color="primary"
                size="large"
                disabled={isLoadingGenerateInvoice}
                style={{
                  background:
                    "linear-gradient(90deg, rgba(236,185,54,1) 0%, rgba(254,95,85,1) 50%, rgba(184,12,9,1) 100%)"
                }}
                onClick={createInvoice}
              >
                Generar factura
              </Button>
            </Grid>

          </Card>
          <Snackbar open={open} autoHideDuration={8000} onClose={handleClose}>
            <Alert onClose={handleClose} severity={resultRequest}>
              {textResultRequest}
            </Alert>
          </Snackbar>
        </Container>
        ) : (
          ""
        )}
    </div>
  );
}

export default App;
